<template>
  <BookingModal
    v-if="showSiteAddressModal"
    :job="job"
    @close="closeSiteAddressModal"
    @save="handleBookingSaved"
  />
  <form
    id="job.customer"
    class="block block-rounded"
    method="POST"
    @submit.prevent="saveJob"
  >
    <div class="block-header block-header-default">
      <h3 v-if="job.id" class="block-title">
        Update Job - {{ job.id }} -
        <span v-if="job.customer.name">for {{ job.customer.name }}</span>
      </h3>

      <div class="btn-group" role="group" aria-label="Horizontal Primary">
        <button
          :disabled="formFields.previous_status === null"
          type="button"
          :class="['btn', 'btn-sm', `btn-${formFields.status_class}`]"
          @click="changeStatus(formFields.previous_status)"
        >
          &#8592;
        </button>
        <button
          type="button"
          disabled
          style="min-width: 150px"
          :class="['btn', 'btn-sm', `btn-${formFields.status_class}`]"
        >
          {{ formFields.status }}
        </button>
        <button
          :disabled="formFields.next_status === null"
          type="button"
          :class="['btn', 'btn-sm', `btn-${formFields.status_class}`]"
          @click="changeStatus(formFields.next_status)"
        >
          &#8594;
        </button>
      </div>
    </div>
    <div>
      <input
        v-if="formFields.id !== null"
        type="hidden"
        name="_method"
        value="PUT"
      />
      <div class="block-content block-content-full">
        <div class="row push">
          <div class="col-lg-4">
            <div class="fs-sm text text-muted">
              <h5>Customer Details</h5>
              <p v-if="props.job.customer.name">
                <strong>{{ props.job.customer.name }}</strong>
                <br />
                <span v-if="props.job.customer.office_address.full_address">
                  {{ props.job.customer.office_address.full_address }}
                </span>
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="fs-sm text text-muted">
              <h5 class="mb-1">Site Address</h5>
              <span v-if="props.job.address">
                {{ props.job.address.full_address }}
              </span>
            </div>
            <div class="fs-sm text text-muted mt-3">
              <h5 class="mb-1">Contact</h5>
              <span v-if="props.job.quote.contact.name">
                <strong>Name :</strong>
                {{ props.job.quote.contact.name }}
                <br />
              </span>
              <span v-if="props.job.quote.contact.email">
                <strong>Email :</strong>
                {{ props.job.quote.contact.email }}
                <br />
              </span>
              <span v-if="props.job.quote.contact.phone">
                <strong>Phone :</strong>
                {{ props.job.quote.contact.phone }}
              </span>
            </div>
          </div>
          <div v-if="formFields.id" class="col-lg-4">
            <div class="fs-sm text text-muted">
              <h5>Details</h5>
              <span v-if="formFields.status">
                <strong>Status:</strong>
                <div
                  :class="formFields.pill_status_class"
                  class="mx-2 rounded-pill"
                  style="font-size: 0.85rem"
                >
                  <strong>
                    {{ formFields.status }}
                  </strong>
                </div>
                <br />
              </span>
              <span v-if="props.job.quote_id">
                <strong>Quote:</strong>
                <a :href="route('quotes.edit', props.job.quote_id)">
                  &nbsp; {{ props.job.quote.reference }}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="block-content block-content-full">
        <div class="row push">
          <table class="table table-striped w-full">
            <thead>
              <tr>
                <th class="col-2">Description</th>
                <th>Erected</th>
                <th>Handover</th>
                <th>Hire Start</th>
                <th>Duration</th>
                <th>Hire End</th>
                <th>Dismantle</th>
                <th>Overhire End</th>
              </tr>
            </thead>
            <tbody v-if="formFields.items.length > 0">
              <tr
                v-for="(item, index) in formFields.items"
                :key="index"
                :class="{
                  'table-warning': isRowHighlighted[index],
                }"
              >
                <td>{{ item.description }}</td>
                <td>
                  <FormDate
                    v-model="item.assembly_date"
                    :name="`item[${index}][assembly_date]`"
                  />
                </td>
                <td>
                  <FormDate
                    v-model="item.handover_date"
                    :name="`item[${index}][handover_date]`"
                    :min-date="item.assembly_date ?? null"
                  />
                </td>
                <td>
                  <FormDate
                    v-model="item.hire_start_date"
                    :name="`item[${index}][hire_start_date]`"
                    :min-date="item.handover_date ?? null"
                  />
                </td>
                <td class="align-middle">
                  {{ item.initial_hire_weeks }} Weeks
                </td>
                <td>
                  <FormDate
                    v-model="item.hire_end_date"
                    :name="`item[${index}][hire_end_date]`"
                    :min-date="item.hire_start_date ?? null"
                  />
                </td>
                <td>
                  <FormDate
                    v-model="item.dismantle_date"
                    :name="`item[${index}][dismantle_date]`"
                    :min-date="item.dismantle_date ?? null"
                  />
                </td>
                <td>
                  <FormDate
                    v-if="isRowHighlighted[index]"
                    v-model="item.overhire_end_date"
                    :name="`item[${index}][overhire_end_date]`"
                    :min-date="item.hire_end_date ?? null"
                  />
                  <div v-else style="width: 150px">-</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="block-content block-content-full block-content-sm bg-body-light d-flex justify-content-between"
    >
      <button
        v-if="formFields.status !== 'Cancelled'"
        type="button"
        class="btn btn-sm btn-danger"
        @click="updateStatus('Cancelled')"
      >
        Mark as Cancelled
      </button>
      <span v-else>&nbsp;</span>

      <button type="submit" class="btn btn-alt-success">
        {{ job.id !== null ? "Update Job" : "Create Job" }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { computed, ref, watch, onMounted } from "vue";
import { addWeeks } from "date-fns";
import BookingModal from "@/components/jobs/modals/bookingModal.vue";
import { useFetch } from "@/components/useFetch.js";
import FormDate from "@/components/form/date.vue";

const showSiteAddressModal = ref(false);
const hasUnsavedChanges = ref(false);
const isSaving = ref(false); // New ref to track save action

function closeSiteAddressModal() {
  showSiteAddressModal.value = false;
}

function handleBookingSaved(data) {
  showSiteAddressModal.value = false;

  formFields.value.items = formFields.value.items.map((item, index) => {
    if (item.assembly_date === null) {
      item.assembly_date = data.assembly_date;
    }
    return item;
  });

  updateStatus("Booked");
}

async function changeStatus(status) {
  if (status === "Booked") {
    if (formFields.value.items.some((item) => item.assembly_date === null)) {
      showSiteAddressModal.value = true;
    } else {
      await updateStatus(status);
    }
  } else {
    await updateStatus(status);
  }
}

async function updateStatus(status) {
  try {
    const response = await useFetch(
      route("api.jobs.update-status", formFields.value.id),
      {
        method: "PATCH",
        body: { status },
      }
    );

    if (response.ok) {
      const data = await response.json();
      formFields.value.status = data.status;
      formFields.value.pill_status_class = data.pill_status_class;
      formFields.value.status_class = data.status_class;
      formFields.value.next_status = data.next_status;
      formFields.value.previous_status = data.previous_status;
      hasUnsavedChanges.value = false;
    } else {
      const errorData = await response.json();
      alert("Failed to update status: " + errorData);
    }
  } catch (error) {
    alert("An error occurred while updating the status: " + error);
  }
}

async function saveJob() {
  try {
    isSaving.value = true; // Set isSaving to true when save action is triggered
    const response = await useFetch(route("api.jobs.update", [props.job.id]), {
      method: "PUT",
      body: formFields.value,
    });

    if (response.ok) {
      const data = await response.json();
      hasUnsavedChanges.value = false;
      window.location.href = route("jobs.edit", data.id);
    } else {
      const errorData = await response.json();
      alert("Failed to save quote:" + errorData);
    }
  } catch (error) {
    alert("An error occurred while saving the quote" + error);
  } finally {
    isSaving.value = false; // Reset isSaving to false after save action is complete
  }
}

const props = defineProps({
  job: Object,
});

const formFields = ref({
  id: props.job.id,
  status_class: props.job.status_class,
  pill_status_class: props.job.pill_status_class,
  quote_id: props.job.quote_id,
  next_status: props.job.next_status ?? null,
  previous_status: props.job.previous_status ?? null,
  status: props.job.status,
  items: props.job.items.map((item) => ({
    ...item,
    editing: item.assembly_date === null,
  })),
});

const isRowHighlighted = computed(() => {
  return formFields.value.items.map((item) => {
    const hireEndDatePassed =
      item.hire_end_date && new Date(item.hire_end_date) < new Date();
    const overhireStartDateNotEmpty = item.overhire_start_date !== null;
    return hireEndDatePassed || overhireStartDateNotEmpty;
  });
});

// Watcher to update hire_end_date when hire_start_date changes and hire_end_date is null
watch(
  () => formFields.value.items,
  (newItems) => {
    newItems.forEach((item) => {
      if (item.hire_start_date && item.hire_end_date === null) {
        item.hire_end_date = addWeeks(
          new Date(item.hire_start_date),
          item.initial_hire_weeks
        );
      }
    });
    hasUnsavedChanges.value = true;
  },
  { deep: true }
);

// Add beforeunload event listener
onMounted(() => {
  window.addEventListener("beforeunload", handleBeforeUnload);
});

function handleBeforeUnload(event) {
  if (hasUnsavedChanges.value && !isSaving.value) {
    event.preventDefault();
    event.returnValue = "";
  }
}
</script>
