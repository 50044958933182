<template>
  <SiteAddressModal
    v-if="showSiteAddressModal"
    :selected-address="formFields.address"
    :customer="formFields.customer"
    :initial-elevations="currentDetails.elevations"
    @close="closeSiteAddressModal"
    @address-selected="handleAddressSelected"
  />
  <ContactModal
    v-if="showContactModal"
    :selected-address="formFields.contact"
    :customer="formFields.customer"
    @close="closeContactModal"
    @contact-selected="handleContactSelected"
  />
  <DetailsModal
    v-if="showDetailsModal"
    :current-data="currentDetails"
    @close="closeDetailsModal"
    @saveDetails="handleDetailsSaved"
  />
  <CustomerModal
    v-if="showCustomerModal"
    @close="toggleCustomerModal"
    @customer-selected="handleCustomerSelected"
  />
  <div class="block block-rounded">
    <div class="block-header block-header-default">
      <h3 class="block-title">
        {{
          formFields.id !== null
            ? "Update Quote " + formFields.reference
            : "Create Quote"
        }}
        <span v-if="props.customer">for {{ customer.name }}</span>
      </h3>

      <button
        v-if="formFields.id !== null"
        id="dropdown-email-quote"
        :disabled="hasUnsavedChanges"
        type="button"
        class="btn btn-sm btn-primary dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Email Quote
      </button>
      <div
        v-if="formFields.id !== null"
        class="dropdown-menu fs-sm"
        aria-labelledby="dropdown-email-quote"
      >
        <button
          type="button"
          class="dropdown-item"
          @click="sendViaEmail('Link', 'Summary')"
        >
          Online Approval - Summary
        </button>
        <button
          type="button"
          class="dropdown-item"
          @click="sendViaEmail('Link', 'Detailed')"
        >
          Online Approval - Detailed
        </button>
        <div class="dropdown-divider"></div>
        <button
          type="button"
          class="dropdown-item"
          @click="sendViaEmail('Attachment', 'Summary')"
        >
          Attachment - Summary
        </button>
        <button
          type="button"
          class="dropdown-item"
          @click="sendViaEmail('Attachment', 'Detailed')"
        >
          Attachment - Detailed
        </button>
      </div>
    </div>
    <div>
      <form id="quote" method="POST" @submit.prevent="saveQuote">
        <input
          v-if="formFields.id !== null"
          type="hidden"
          name="_method"
          value="PUT"
        />
        <div class="block-content block-content-full">
          <div class="row push">
            <div class="col-lg-4">
              <div class="fs-sm text text-muted">
                <h5>
                  Customer Details
                  <span class="text-danger">*</span>
                </h5>
                <p v-if="formFields.customer && formFields.customer.name">
                  <strong>{{ formFields.customer.name }}</strong>
                  <br />
                  <span v-if="formFields.customer.office_address.full_address">
                    {{ formFields.customer.office_address.full_address }}
                  </span>
                </p>

                <div v-if="!props.customer" class="block mt-1">
                  <button
                    type="button"
                    class="btn btn-sm btn-alt-primary"
                    @click="showCustomerModal = true"
                  >
                    Select Customer
                  </button>
                </div>

                <div v-if="formFields.customer.id">
                  <h5 class="mb-2">Payment Terms</h5>
                  <span>Type</span>
                  <span class="text-danger">*</span>
                  <Select
                    v-model="formFields.payment_term_type"
                    class="w-50"
                    name="payment_term_type"
                    :values="paymentTermsOptions"
                    :disabled-value-text="`Select Payment Terms`"
                  />
                  <div
                    v-if="
                      formFields.payment_term_type == 'Days After Invoice Date'
                    "
                    class="mt-2"
                  >
                    <label class="text-sm" for="payment_term_days">
                      Number of days
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      id="payment_term_days"
                      v-model="formFields.payment_term_days"
                      type="number"
                      class="form-control w-50"
                      name="payment_term_days"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="formFields.customer.id" class="col-lg-4">
              <div class="fs-sm text text-muted">
                <h5 class="mb-1">
                  Site Address
                  <span class="text-danger">*</span>
                </h5>
                <span v-if="formFields.address.line_1">
                  {{ formFields.address.line_1 }},
                </span>
                <span v-if="formFields.address.line_2">
                  {{ formFields.address.line_2 }},
                </span>
                <span v-if="formFields.address.city">
                  {{ formFields.address.city }},
                </span>
                <span v-if="formFields.address.postcode">
                  {{ formFields.address.postcode }}
                </span>

                <div class="block mt-2">
                  <button
                    type="button"
                    class="btn btn-sm btn-alt-primary"
                    @click="toggleSiteAddressModal()"
                  >
                    Select Site Address
                  </button>
                </div>
              </div>
              <div class="fs-sm text text-muted">
                <h5 class="mb-1">
                  Contact
                  <span class="text-danger">*</span>
                </h5>
                <span v-if="formFields.contact.name">
                  <strong>Name :</strong>
                  {{ formFields.contact.name }}
                  <br />
                </span>
                <span v-if="formFields.contact.email">
                  <strong>Email :</strong>
                  {{ formFields.contact.email }}
                  <br />
                </span>
                <span v-if="formFields.contact.phone">
                  <strong>Phone :</strong>
                  {{ formFields.contact.phone }}
                </span>
                <div class="block mt-3">
                  <button
                    type="button"
                    class="btn btn-sm btn-alt-primary"
                    @click="toggleContactModal()"
                  >
                    Select Contact
                  </button>
                </div>
              </div>
            </div>
            <div v-if="formFields.id" class="col-lg-4">
              <div class="fs-sm text text-muted">
                <h5>Quote Details</h5>
                <span v-if="props.quote.status">
                  <strong>Status:</strong>
                  <span
                    :class="[
                      statusClasses[formFields.status],
                      'mx-2 rounded-pill',
                    ]"
                    style="font-size: 0.85rem"
                  >
                    <strong>{{ formFields.status }}</strong>
                  </span>
                  <br />
                </span>
                <span
                  v-if="
                    formFields.status === 'Rejected' &&
                    formFields.rejection_reason
                  "
                >
                  <strong>Rejection Reason:</strong>
                  {{ formFields.rejection_reason }}
                  <br />
                </span>
                <span v-if="formFields.id">
                  <strong>Quote Reference:</strong>
                  {{ formFields.reference }}
                </span>
                <span v-if="formFields.created_at">
                  <br />
                  <strong>Created:</strong>
                  {{ formFields.created_at }}
                </span>
                <span v-if="formFields.updated_at">
                  <br />
                  <strong>Updated:</strong>
                  {{ formFields.updated_at }}
                </span>
                <span>
                  <br />
                  <strong>Summary Quote link:</strong>
                  <a :href="props.publicLinkSummary" target="_blank">
                    Summary Quote Link
                  </a>
                </span>
                <span>
                  <br />
                  <strong>Detailed Quote link:</strong>
                  <a :href="props.publicLinkDetailed" target="_blank">
                    Detailed Quote Link
                  </a>
                </span>
              </div>
              <div class="block mt-3">
                <div v-if="formFields.status !== 'Converted'" class="row">
                  <div v-if="formFields.status !== 'Accepted'" class="col">
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      @click="updateStatus('Accepted')"
                    >
                      Mark as Accepted
                    </button>
                  </div>
                  <div v-if="formFields.status === 'Accepted'" class="col">
                    <button
                      type="button"
                      class="btn btn-sm btn-success"
                      @click="convertToJob()"
                    >
                      Convert To Job
                    </button>
                  </div>
                  <div
                    v-if="
                      formFields.status !== 'Rejected' &&
                      formFields.status !== 'Created'
                    "
                    class="col"
                  >
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="updateStatus('Rejected')"
                    >
                      Mark as Rejected
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block-content block-content-full">
          <div class="row push">
            <div class="col-lg-12 col-xl-12">
              <h5 class="text-muted">Hire Costs</h5>
              <div class="mb-4">
                <table class="table table-striped w-full">
                  <thead>
                    <tr>
                      <th class="col-4">
                        Description
                        <span class="text-danger">*</span>
                      </th>
                      <th>
                        Initial Hire Weeks
                        <span class="text-danger">*</span>
                      </th>
                      <th>
                        Initial Hire Price
                        <span class="text-danger">*</span>
                      </th>
                      <th>
                        Overhire
                        <span class="text-danger">*</span>
                      </th>
                      <th>
                        VAT Rate
                        <span class="text-danger">*</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="hireItems.length > 0">
                      <tr v-for="(item, index) in hireItems" :key="index">
                        <td>
                          <input
                            v-model="item.description"
                            type="text"
                            class="form-control"
                            :name="`items[${index}][description]`"
                            required
                          />
                        </td>
                        <td>
                          <input
                            v-model="item.initial_hire_weeks"
                            type="number"
                            class="form-control"
                            :name="`items[${index}][initial_hire_weeks]`"
                            step="1"
                            required
                          />
                        </td>
                        <td>
                          <div class="input-group">
                            <span class="input-group-text">£</span>
                            <input
                              v-model="item.price"
                              type="number"
                              class="form-control"
                              :name="`items[${index}][price]`"
                              required
                            />
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <span class="input-group-text">£</span>
                            <input
                              v-model="item.overhire_rate"
                              type="number"
                              class="form-control"
                              :name="`items[${index}][overhire_rate]`"
                              required
                            />
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <Select
                              v-model="item.tax_rate"
                              :name="`items[${index}][tax_rate]`"
                              :values="taxRates"
                              :field-name="`full_label`"
                            />
                          </div>
                        </td>
                        <td class="text-end">
                          <button
                            type="button"
                            class="btn btn-sm btn-alt-primary"
                            @click="toggleDetailsModal(index)"
                          >
                            Details
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-sm btn-alt-danger"
                            @click="confirmRemoveItem(index, 'Hire')"
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="8" class="text-center">
                        <button
                          type="button"
                          class="btn btn-sm btn-alt-primary w-25"
                          @click="addHireItem"
                        >
                          Add Scaffolding Hire Item
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- One Off Costs -->
              <h5 class="text-muted">One Off Costs</h5>

              <div class="mb-4">
                <table class="table table-striped w-full">
                  <thead>
                    <tr>
                      <th class="col-3">
                        Description
                        <span class="text-danger">*</span>
                      </th>
                      <th>
                        Quantity
                        <span class="text-danger">*</span>
                      </th>
                      <th>
                        Price
                        <span class="text-danger">*</span>
                      </th>
                      <th>
                        VAT Rate
                        <span class="text-danger">*</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="oneOffItems.length > 0">
                      <tr v-for="(item, index) in oneOffItems" :key="index">
                        <td>
                          <input
                            v-model="item.description"
                            type="text"
                            class="form-control"
                            :name="`oneoff_items[${index}][description]`"
                            required
                          />
                        </td>
                        <td>
                          <input
                            v-model="item.quantity"
                            type="number"
                            class="form-control"
                            :name="`oneoff_items[${index}][quantity]`"
                            required
                          />
                        </td>
                        <td>
                          <div class="input-group">
                            <span class="input-group-text">£</span>
                            <input
                              v-model="item.price"
                              type="number"
                              class="form-control"
                              :name="`oneoff_items[${index}][price]`"
                              required
                            />
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <Select
                              v-model="item.tax_rate"
                              :name="`items[${index}][tax_rate]`"
                              :values="taxRates"
                              :field-name="`full_label`"
                            />
                          </div>
                        </td>
                        <td class="text-end">
                          <button
                            type="button"
                            class="btn btn-sm btn-alt-danger"
                            @click="confirmRemoveItem(index, 'OneOff')"
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="5" class="text-center">
                        <button
                          type="button"
                          class="btn btn-sm btn-alt-primary w-25"
                          @click="addOneOffItem"
                        >
                          Add One Off Charge
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-end">
                  <table>
                    <tbody>
                      <tr>
                        <td class="fw-bold text-end">Total One Off Charges:</td>
                        <td>
                          {{ formatCurrency(totalOneOffCost) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="fw-bold text-end">Total Hire Charges:</td>
                        <td>
                          {{ formatCurrency(totalHireCost) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="fw-bold text-end">Total Net:</td>
                        <td>
                          {{ formatCurrency(totalNetCost) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="fw-bold text-end">VAT:</td>
                        <td>
                          {{ formatCurrency(totalVatCost) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="fw-bold text-end">Grand Total:</td>
                        <td>
                          {{ formatCurrency(totalCost) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div
      class="block-content block-content-full block-content-sm bg-body-light text-end"
    >
      <button
        type="submit"
        form="quote"
        class="btn btn-alt-success"
        :class="{ disabled: !canBeSaved() }"
        :disabled="!canBeSaved()"
      >
        {{ formFields.id !== null ? "Update Quote" : "Create Quote" }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from "vue";
import { useFetch } from "@/components/useFetch.js";
import SiteAddressModal from "@/components/quotes/modals/siteaddress.vue";
import ContactModal from "@/components/quotes/modals/contact.vue";
import DetailsModal from "@/components/quotes/modals/detailsModal.vue";
import CustomerModal from "@/components/quotes/modals/customer.vue";
import Select from "@/components/form/select.vue";

const showSiteAddressModal = ref(false);
const showContactModal = ref(false);
const showDetailsModal = ref(false);
const showCustomerModal = ref(false);
const currentDetails = ref([
  {
    elevations: [],
    details: [],
    uploads: [],
    item_id: null,
    quote_id: null,
    temp_hash: null,
  },
]);
const currentItemIndex = ref(null);
const hasUnsavedChanges = ref(false);
const isSaving = ref(false); // New ref to track save action

const props = defineProps({
  route: String,
  quote: {
    type: Object,
  },
  customer: {
    type: Object,
  },
  publicLinkSummary: {
    type: String,
    default: null,
  },
  publicLinkDetailed: {
    type: String,
    default: null,
  },
  taxRates: {
    type: Object,
    required: true,
  },
  statusClasses: {
    type: Object,
  },
  paymentTermsOptions: {
    type: Object,
  },
  lineItemTypes: {
    type: Object,
  },
});

function getDefaultTaxRate(taxRates) {
  const taxRatesArray = Object.values(taxRates);
  defaultTaxRate.value = taxRatesArray.find((rate) => rate.default === true);
}

const taxRates = ref(props.taxRates);
const defaultTaxRate = ref(null);
getDefaultTaxRate(taxRates.value);

const formFields = ref({
  id: props.quote?.id ?? null,
  reference: props.quote?.reference ?? null,
  status: props.quote?.status ?? null,
  rejection_reason: props.quote?.rejection_reason ?? null,
  customer: props.customer ?? {},
  customer_id: props.customer?.id ?? null,
  items: props.quote?.items ?? [],
  payment_term_type: null,
  payment_term_days: null,
  address_id: props.quote?.address_id ?? null,
  contact_id: props.quote?.contact?.id ?? null,
  address: {
    line_1: props.quote?.address?.line_1 ?? null,
    line_2: props.quote?.address?.line_2 ?? null,
    city: props.quote?.address?.city ?? null,
    postcode: props.quote?.address?.postcode ?? null,
  },
  contact: {
    name: props.quote?.contact?.name ?? null,
    email: props.quote?.contact?.email ?? null,
    phone: props.quote?.contact?.phone ?? null,
  },
});

function setPaymentTypes() {
  if (props.quote && props.quote.payment_term_type) {
    formFields.value.payment_term_type = props.quote.payment_term_type;
    formFields.value.payment_term_days = props.quote.payment_term_days;
  } else if (props.customer) {
    formFields.value.payment_term_type =
      props.customer.default_payment_term_type;
    formFields.value.payment_term_days =
      props.customer.default_payment_term_days;
  }
}

setPaymentTypes();
function toggleDetailsModal(index) {
  currentItemIndex.value = index;
  currentDetails.value = {
    elevations: hireItems.value[index].elevations,
    paragraphs: hireItems.value[index].details,
    uploads: hireItems.value[index].uploads,
    item_id: hireItems.value[index].id,
    quote_id: formFields.value.id,
    temp_hash: hireItems.value[index].temp_hash,
  };
  showDetailsModal.value = !showDetailsModal.value;
}

function closeDetailsModal() {
  showDetailsModal.value = false;
}
function handleDetailsSaved(details) {
  // find the line item by id, if the id is null, find by temp_hash
  const itemIndex = formFields.value.items.findIndex((item) => {
    if (details.item_id) {
      return item.id === details.item_id;
    } else {
      return item.temp_hash === details.temp_hash;
    }
  });

  // if the item is found, update the details
  if (itemIndex !== -1) {
    formFields.value.items[itemIndex].elevations = details.elevations;
    formFields.value.items[itemIndex].details = details.paragraphs;
    formFields.value.items[itemIndex].uploads = details.uploads;
  }

  showDetailsModal.value = false;
}

// Function to toggle the modal visibility
function toggleSiteAddressModal() {
  showSiteAddressModal.value = !showSiteAddressModal.value;
}

// Function to close the modal
function closeSiteAddressModal() {
  showSiteAddressModal.value = false;
}

function toggleContactModal() {
  showContactModal.value = !showContactModal.value;
}

function toggleCustomerModal() {
  showCustomerModal.value = !showCustomerModal.value;
}

// Function to close the modal
function closeContactModal() {
  showContactModal.value = false;
}

// Function to handle the address-selected event
function handleAddressSelected(address) {
  formFields.value.address = address; // Update the formFields with the selected address
  formFields.value.address_id = address.id; // Set the site address id
  showSiteAddressModal.value = false; // Close the modal
}

function handleCustomerSelected(customer) {
  formFields.value.customer = customer; // Update the formFields with the selected customer
  formFields.value.customer_id = customer.id; // Set the customer id
  formFields.value.payment_term_type = customer.default_payment_term_type;
  formFields.value.payment_term_days = customer.default_payment_term_days;
  showCustomerModal.value = false; // Close the modal
}

function handleContactSelected(contact) {
  formFields.value.contact = contact; // Update the formFields with the selected contact
  formFields.value.contact_id = contact.id; // Set the contact id
  showContactModal.value = false; // Close the modal
}

async function updateStatus(status) {
  try {
    isSaving.value = true;

    const response = await useFetch(
      route("api.quotes.update-status", formFields.value.id),
      {
        method: "PATCH",
        body: { status },
      }
    );

    if (response.ok) {
      const data = await response.json();
      formFields.value.status = data.status;
      hasUnsavedChanges.value = false;
      window.removeEventListener("beforeunload", handleBeforeUnload); // Remove the event listener
    } else {
      const errorData = await response.json();
      alert("Failed to update status: " + errorData);
    }
  } catch (error) {
    alert("An error occurred while updating the status: " + error);
  } finally {
    isSaving.value = false;
  }
}

async function sendViaEmail(emailType, quoteType) {
  try {
    const response = await useFetch(
      route("api.quotes.email", formFields.value.id),
      {
        method: "POST",
        body: { emailType, quoteType },
      }
    );

    if (response.ok) {
      const result = await Swal.fire({
        title: "Email Sent",
        text: "The email has been sent successfully",
        icon: "success",
      });

      if (result.isConfirmed) {
        window.location.href = route("quotes.index");
      }
    } else {
      const errorData = await response.json();
      await Swal.fire({
        title: "Failed to send email",
        text: errorData.message,
        icon: "error",
      });
    }
  } catch (error) {
    alert("An error occurred while updating the status: " + error);
  }
}

async function convertToJob() {
  try {
    const response = await useFetch(
      route("api.quotes.convert", formFields.value.id),
      {
        method: "POST",
      }
    );

    if (response.ok) {
      const data = await response.json();
      formFields.value.status = data.status;
    } else {
      const errorData = await response.json();
      alert("Failed to convert to job: " + errorData);
    }
  } catch (error) {
    alert("An error occurred whilst converting this quote to a job: " + error);
  }
}

const totalVatCost = ref(0);
const totalHireCost = ref(0);
const totalOneOffCost = ref(0);
const totalCost = ref(0);
const totalNetCost = computed(() => {
  return totalHireCost.value + totalOneOffCost.value;
});

function addOneOffItem() {
  addItem("OneOff");
}

function addHireItem() {
  addItem("Hire");
}

function addItem(type) {
  formFields.value.items.push({
    id: null,
    description: null,
    type: type,
    tax_rate: defaultTaxRate.value,
    quantity: 1,
    initial_hire_weeks: 1,
    price: 0,
    overhire_rate: 0,
    vat_amount: null,
    total_with_vat: null,
    elevations: [],
    details: [],
    uploads: [],
    temp_hash: generateTempHash(),
  });
}

function generateTempHash() {
  return Math.random().toString(36).substring(2, 15);
}

function formatCurrency(value) {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(value);
}

async function saveQuote() {
  try {
    isSaving.value = true; // Set isSaving to true when save action is triggered
    if (props.quote?.id === null || props.quote?.id === undefined) {
      const response = await useFetch(
        route("api.customers.quotes.store", [formFields.value.customer_id]),
        {
          method: "POST",
          body: formFields.value,
        }
      );

      if (response.ok) {
        const data = await response.json();

        // Redirect to the quote page
        window.location.href = route("quotes.edit", data.id);
      } else {
        const errorData = await response.json();
        alert("Failed to save quote: " + errorData.message);
      }
    } else {
      const response = await useFetch(
        route("api.customers.quotes.update", [
          formFields.value.customer_id,
          props.quote?.id,
        ]),
        {
          method: "PATCH",
          body: formFields.value,
        }
      );

      if (response.ok) {
        const data = await response.json();

        // Redirect to the quote page
        window.location.href = route("quotes.edit", data.id);
      } else {
        const errorData = await response.json();
        alert("Failed to save quote: " + errorData.message);
      }
    }
  } catch (error) {
    alert("An error occurred while saving the quote" + error);
  } finally {
    isSaving.value = false; // Reset isSaving to false after save action is complete
  }
}

if (formFields.value.items.length > 0) {
  // if an item does not have a VAT rate, set it to 20%
  formFields.value.items.forEach((item) => {
    if (!item.tax_rate) {
      item.tax_rate = defaultTaxRate.value;
    }
  });
}

function confirmRemoveItem(index, type) {
  Swal.fire({
    title: "Are you sure?",
    text: "This item and all its details will be removed",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    confirmButtonColor: "#d33",
    cancelButtonText: "No, cancel!",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      removeItem(index, type);
    }
  });
}

function removeItem(index, type) {
  // get all items of the relevant type
  const items = formFields.value.items.filter((item) => item.type === type);

  // get the original index of the item
  const originalIndex = formFields.value.items.indexOf(items[index]);

  // remove the item from the items array
  formFields.value.items.splice(originalIndex, 1);
}

const hireItems = computed(() => {
  return formFields.value.items.filter((item) => item.type === "Hire");
});

const oneOffItems = computed(() => {
  return formFields.value.items.filter((item) => item.type === "OneOff");
});
function calculateTotals() {
  // Calculate the total for each item
  formFields.value.items.forEach((item) => {
    // make sure the initial hire price is set
    if (!item.price) {
      return;
    }

    // Calculate the total for the item
    item.vat_amount = (item.price * item.quantity * item.tax_rate.rate) / 100;
    item.total_with_vat = item.price * item.quantity + item.vat_amount;
  });

  if (formFields.value.items.length === 0) {
    return;
  }

  // Calculate the total hire cost
  totalHireCost.value = formFields.value.items
    .filter((item) => item.type === "Hire")
    .reduce((acc, item) => acc + item.price, 0);

  // Calculate the total one-off cost
  totalOneOffCost.value = formFields.value.items
    .filter((item) => item.type === "OneOff")
    .reduce((acc, item) => acc + item.price * item.quantity, 0);

  // Calculate the total VAT cost
  totalVatCost.value = formFields.value.items.reduce((acc, item) => {
    if (!item.vat_amount) {
      return acc;
    }
    return acc + item.vat_amount;
  }, 0);

  // Calculate the total cost
  let total = 0;
  formFields.value.items.forEach((item) => {
    total += item.total_with_vat;
  });
  totalCost.value = total;
}

if (props.quote?.items) {
  // loop through each item and set the price to the major value of the array
  formFields.value.items.forEach((item) => {
    item.price = parseInt(item.price["major_value"], 10);
    item.overhire_rate = parseInt(item.overhire_rate["major_value"], 10);
  });
  calculateTotals();
}

watch(
  formFields,
  () => {
    calculateTotals();
    hasUnsavedChanges.value = true;
  },
  { deep: true }
);

// Add beforeunload event listener
onMounted(() => {
  window.addEventListener("beforeunload", handleBeforeUnload);
});

function handleBeforeUnload(event) {
  if (hasUnsavedChanges.value && !isSaving.value) {
    event.preventDefault();
    event.returnValue = "";
  }
}

function canBeSaved() {
  return !!(
    formFields.value.customer_id &&
    formFields.value.address_id &&
    formFields.value.contact_id
  );
}
</script>
