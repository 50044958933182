<template>
  <Modal
    title="Manage Details"
    modal-classes="modal-xl modal-top"
    @save="saveData"
  >
    <ul class="nav nav-tabs nav-tabs-block" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          id="btabs-static-elevations-tab"
          type="button"
          class="nav-link active"
          data-bs-toggle="tab"
          data-bs-target="#btabs-static-elevations"
          role="tab"
          aria-controls="btabs-static-elevations"
          aria-selected="true"
        >
          Elevations
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          id="btabs-static-paragraphs-tab"
          type="button"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#btabs-static-paragraphs"
          role="tab"
          aria-controls="btabs-static-paragraphs"
          aria-selected="false"
          tabindex="-1"
        >
          Paragraphs
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          id="btabs-static-uploads-tab"
          type="button"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#btabs-static-uploads"
          role="tab"
          aria-controls="btabs-static-uploads"
          aria-selected="false"
          tabindex="-1"
        >
          Uploads
        </button>
      </li>
    </ul>
    <div class="block-content tab-content mb-4">
      <div
        id="btabs-static-elevations"
        class="tab-pane active show"
        role="tabpanel"
        aria-labelledby="btabs-static-elevations-tab"
        tabindex="0"
      >
        <h4 class="fw-normal">Manage Elevations</h4>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Description</th>
              <th>Length</th>
              <th>Width</th>
              <th>Lifts</th>
              <th>Lift Height</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(elevation, index) in data.elevations" :key="index">
              <td>
                <input
                  v-model="elevation.description"
                  type="text"
                  class="form-control"
                  required
                />
              </td>
              <td>
                <input
                  v-model="elevation.length"
                  type="number"
                  step=".01"
                  class="form-control"
                  required
                />
              </td>
              <td>
                <input
                  v-model="elevation.width"
                  type="number"
                  step=".01"
                  class="form-control"
                  required
                />
              </td>
              <td>
                <input
                  v-model="elevation.lift_count"
                  type="number"
                  class="form-control"
                  required
                />
              </td>
              <td>
                <input
                  v-model="elevation.lift_height"
                  type="number"
                  step=".01"
                  class="form-control"
                  required
                />
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="removeElevation(index)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          type="button"
          class="btn btn-sm btn-primary"
          @click="addElevation"
        >
          Add Elevation
        </button>
      </div>
      <div
        id="btabs-static-paragraphs"
        class="tab-pane"
        role="tabpanel"
        aria-labelledby="btabs-static-paragraphs-tab"
        tabindex="0"
      >
        <h4 class="fw-normal">Manage Paragraphs</h4>
        <div class="block block-rounded row g-0">
          <ul
            class="nav nav-tabs nav-tabs-block flex-md-column col-md-4"
            role="tablist"
          >
            <li class="nav-item d-md-flex flex-md-column" role="presentation">
              <button class="btn btn-primary" @click="addParagraph">
                Add Paragraph
              </button>
            </li>
            <li
              v-for="(paragraph, index) in data.paragraphs"
              :key="index"
              class="nav-item d-md-flex flex-md-column"
              role="presentation"
            >
              <div class="d-flex align-items-center">
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-2"
                  @click="removeParagraph(index)"
                >
                  X
                </button>
                <button
                  :id="'btabs-vertical-paragraphs-tab-' + index"
                  class="nav-link bg-transparent text-md-start"
                  @click="selectParagraph(index)"
                >
                  {{ paragraph.title }}
                </button>
              </div>
            </li>
          </ul>
          <div class="col-md-8">
            <div v-if="selectedParagraph !== null">
              <div style="padding-left: 25px">
                <div>
                  <input
                    v-model="data.paragraphs[selectedParagraph].title"
                    class="form-control"
                  />
                </div>
                <div>
                  <Tiptap
                    v-model="data.paragraphs[selectedParagraph].description"
                  />
                </div>
              </div>
            </div>
            <div v-else class="text-center">No Paragraph Selected</div>
          </div>
        </div>
      </div>

      <div
        id="btabs-static-uploads"
        class="tab-pane"
        role="tabpanel"
        aria-labelledby="btabs-static-uploads-tab"
        tabindex="0"
      >
        <h4 class="fw-normal">Manage Uploads</h4>
        <direct-upload
          :url-complete="getCompletedUploadRoute()"
          :url-upload="getBeginUploadRoute()"
          :droppable="true"
          @uploaded="fileUploaded"
        ></direct-upload>
        <table v-if="uploads && uploads.length > 0" class="w-100 mt-6">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th class="px-5">File Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(upload, index) in uploads" :key="index">
              <td>
                <img
                  :src="upload.thumbnail_url"
                  alt="Thumbnail"
                  style="max-height: 350px; max-width: 350px"
                />
              </td>
              <td style="width: 100%">{{ upload.filename ?? upload.name }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="removeUpload(index)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { defineProps, ref, defineEmits } from "vue";
import axios from "axios";
import DirectUpload from "@/components/directUpload.vue";
import Modal from "@/components/modal.vue";
import Tiptap from "@/components/tiptap.vue";

const props = defineProps({
  currentData: {
    type: Object,
    default: () => ({
      elevations: [],
      paragraphs: [],
      uploads: [],
      quote_id: false,
      item_id: false,
      temp_hash: null,
    }),
  },
});

const data = ref(props.currentData);
const selectedParagraph = ref(null);
const uploads = ref([]);

function addElevation() {
  data.value.elevations.push({
    description: "",
    length: "",
    width: "",
    lift_count: "",
    lift_height: "",
  });
}

function removeElevation(index) {
  data.value.elevations.splice(index, 1);
}

function addParagraph() {
  const currentParagraphCount = data.value.paragraphs.length + 1;
  data.value.paragraphs.push({
    title: "Paragraph " + currentParagraphCount,
    description: "",
  });
}

function removeParagraph(index) {
  data.value.paragraphs.splice(index, 1);
}

function selectParagraph(index) {
  selectedParagraph.value = index;
}

function getBeginUploadRoute() {
  if (!props.currentData.quote_id || !props.currentData.item_id) {
    return route("api.quotes.temp.items.upload.begin", {
      tempHash: props.currentData.temp_hash,
    });
  }
  return route("api.quotes.items.upload.begin", {
    quote: props.currentData.quote_id,
    quoteItem: props.currentData.item_id,
  });
}

function getCompletedUploadRoute() {
  if (!props.currentData.quote_id || !props.currentData.item_id) {
    return route("api.quotes.temp.items.upload.complete", {
      tempHash: props.currentData.temp_hash,
    });
  }
  return route("api.quotes.items.upload.complete", {
    quote: props.currentData.quote_id,
    quoteItem: props.currentData.item_id,
  });
}

async function fetchUploads() {
  if (!props.currentData.quote_id || !props.currentData.item_id) {
    uploads.value = data.value.uploads;
    return;
  }
  const response = await axios.get(
    route("api.quotes.items.upload.files", {
      quote: props.currentData.quote_id,
      quoteItem: props.currentData.item_id,
    })
  );
  uploads.value = response.data;

  data.value.uploads = [];

  uploads.value.forEach((upload) => {
    data.value.uploads.push(upload[0]);
  });
}

fetchUploads();

async function removeUpload(index) {
  const result = await Swal.fire({
    title: "Are you sure?",
    text: "This action cannot be undone",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, remove it",
    cancelButtonText: "No, keep it",
  });

  if (result.isConfirmed) {
    await axios.delete(uploads.value[index].delete_url);
    await fetchUploads();
  }
}

function fileUploaded(uploadData) {
  if (!props.currentData.quote_id || !props.currentData.item_id) {
    data.value.uploads.push(uploadData);
  }

  fetchUploads();
}

const emit = defineEmits(["saveDetails"]);

function saveData() {
  if (
    data.value.elevations.find(
      (elevations) =>
        elevations.description === "" ||
        elevations.length === "" ||
        elevations.width === "" ||
        elevations.lift_count === "" ||
        elevations.lift_height === ""
    )
  ) {
    Swal.fire({
      title: "Error",
      text: "Please complete all fields for each elevation",
      icon: "error",
    });
    return;
  }

  emit("saveDetails", data.value);
}

if (data.value.paragraphs.length > 0 && selectedParagraph.value == null) {
  selectParagraph(0);
}
</script>

<style scoped>
.selected-paragraph {
  background-color: #1f2937;
}
</style>
