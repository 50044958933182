<template>
  <div>
    <label
      v-if="label"
      :for="name"
      class="block text-sm font-medium text-gray-700"
    >
      {{ label }}
      <span v-if="required" class="text-red-500">*</span>
    </label>
    <select
      :id="name"
      v-model="value"
      :name="name"
      :disabled="disabled"
      :required="required"
      class="form-select"
    >
      <option value="" disabled selected>{{ disabledValueText }}</option>
      <option v-for="value in values" :key="value.id" :value="value">
        {{ value[fieldName] ?? value }}
      </option>
    </select>
    <p v-if="error" class="mt-2 text-sm text-red-600">{{ error }}</p>
  </div>
</template>

<script setup>
const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    required: false,
  },
  error: {
    type: String,
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  values: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  fieldName: {
    type: String,
  },
  disabledValueText: {
    type: String,
    default: "Select a rate",
  },
});

const value = defineModel({ type: Object, default: null });
</script>

<style></style>
