<template>
  <form
    id="customer"
    class="block block-rounded"
    method="POST"
    @submit.prevent="createCustomer"
  >
    <div class="block-header block-header-default">
      <h3 v-if="formFields.id !== null" class="block-title">
        Update Customer - {{ customer.name }}
      </h3>
      <h3 v-else class="block-title">Create Customer</h3>
    </div>
    <input
      v-if="formFields.id !== null"
      type="hidden"
      name="_method"
      value="PUT"
    />
    <div class="block-content block-content-full">
      <div class="row push">
        <div class="col-lg-2">
          <p><strong>Search Companies House</strong></p>
        </div>
        <div class="col-lg-10 col-xl-7">
          <div class="mb-2">
            <div v-if="formFields.id === null" class="card p-3 bg-info-light">
              <div class="mb-4">
                <label class="form-label">Company Name</label>
                <input
                  v-model="companieshousesearch"
                  type="text"
                  class="form-control"
                />
              </div>
              <div v-if="companyResults.length > 0" class="mb-2">
                <select
                  class="form-select"
                  @change="selectCompany($event.target.value)"
                >
                  <option value="" selected>Select a company</option>
                  <option
                    v-for="(company, key) in companyResults"
                    :key="key"
                    :value="key"
                  >
                    {{ company.company_name }}
                  </option>
                </select>
              </div>
              <div v-else-if="companyResults.length === 0 && hasSearched">
                <p class="text-muted mb-0">
                  No companies found, please try again.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row push">
        <div style="font-size: 20px" class="mb-3 text-center text-muted">
          Or Enter Manually
        </div>
      </div>
      <div class="row push">
        <div class="col-lg-2">
          <p><strong>Company details</strong></p>
        </div>
        <div class="col-lg-10 col-xl-7">
          <div class="mb-4">
            <label class="form-label" for="name">
              Name
              <span class="text-danger">*</span>
            </label>
            <input
              id="name"
              v-model="formFields.name"
              type="text"
              class="form-control"
              name="name"
              required
            />
          </div>
          <div class="mb-4">
            <address-autocomplete
              @address-selected="handleAddressSelected"
            ></address-autocomplete>
          </div>
          <div class="mb-4">
            <label class="form-label" for="address['line_1']">
              Address Line 1
              <span class="text-danger">*</span>
            </label>
            <input
              id="address['line_1']"
              v-model="formFields.office_address.line_1"
              type="text"
              class="form-control"
              name="office_address['line_1']"
              required
            />
          </div>
          <div class="mb-4">
            <label class="form-label" for="address['line_2']">
              Address Line 2
            </label>
            <input
              id="address['line_2']"
              v-model="formFields.office_address.line_2"
              type="text"
              class="form-control"
              name="office_address['line_2']"
            />
          </div>
          <div class="mb-4">
            <label class="form-label" for="address['city']">
              City
              <span class="text-danger">*</span>
            </label>
            <input
              id="address['city']"
              v-model="formFields.office_address.city"
              type="text"
              class="form-control"
              name="office_address['city']"
              required
            />
          </div>
          <div class="mb-4">
            <label class="form-label" for="address['county']">County</label>
            <input
              id="address['county']"
              v-model="formFields.office_address.county"
              type="text"
              class="form-control"
              name="office_address['county']"
            />
          </div>
          <div class="mb-4">
            <label class="form-label" for="address['postcode']">
              Postcode
              <span class="text-danger">*</span>
            </label>
            <input
              id="address['postcode']"
              v-model="formFields.office_address.postcode"
              type="text"
              class="form-control"
              name="office_address['postcode']"
              required
            />
          </div>
          <div class="row">
            <div class="mb-4 col-6">
              <label class="form-label" for="office_email">
                Email
                <span class="text-danger">*</span>
              </label>
              <input
                id="office_email"
                v-model="formFields.office_email"
                type="email"
                class="form-control"
                name="office_email"
                required
              />
            </div>
            <div class="mb-4 col-6">
              <label class="form-label" for="office_phone">
                Phone
                <span class="text-danger">*</span>
              </label>
              <input
                id="office_phone"
                v-model="formFields.office_phone"
                type="text"
                class="form-control"
                name="office_phone"
                required
              />
            </div>
          </div>
          <div class="row">
            <div class="mb-4 col-6">
              <label class="form-label" for="company_number">
                Company Number
              </label>
              <input
                id="company_number"
                v-model="formFields.company_number"
                type="text"
                class="form-control"
                name="company_number"
              />
            </div>
          </div>
          <div class="row">
            <div class="mb-4 col-6">
              <label class="form-label" for="payment_terms">
                Payment Terms
                <span class="text-danger">*</span>
              </label>
              <Select
                id="payment_terms"
                v-model="formFields.default_payment_term_type"
                :values="paymentTermsOptions"
                name="default_payment_term_type"
                required
              />
            </div>
            <div
              v-if="
                formFields.default_payment_term_type ===
                'Days After Invoice Date'
              "
              class="mb-4 col-6"
            >
              <label class="form-label" for="payment_term_days">
                Number of Days
                <span class="text-danger">*</span>
              </label>
              <input
                id="payment_term_days"
                v-model="formFields.default_payment_term_days"
                name="default_payment_term_days"
                class="form-control"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="border-top border-1 row push pt-2"
        style="border-color: #1f2937 !important"
      >
        <div class="col-lg-2">
          <p><strong>Primary Contact Details</strong></p>
        </div>
        <div class="col-lg-8 col-xl-5">
          <div class="mb-4">
            <label class="form-label" for="primary_contact_name">
              Name
              <span class="text-danger">*</span>
            </label>
            <input
              id="primary_contact_name"
              v-model="formFields.primary_contact.name"
              type="text"
              class="form-control"
              name="primary_contact['name']"
              required
            />
          </div>
          <div class="mb-4">
            <label class="form-label" for="primary_contact_email">
              Email
              <span class="text-danger">*</span>
            </label>
            <input
              id="primary_contact_email"
              v-model="formFields.primary_contact.email"
              type="email"
              class="form-control"
              name="primary_contact['email']"
              required
            />
          </div>
          <div class="mb-4">
            <label class="form-label" for="primary_contact_phone">
              Phone
              <span class="text-danger">*</span>
            </label>
            <input
              id="primary_contact_phone"
              v-model="formFields.primary_contact.phone"
              type="text"
              class="form-control"
              name="primary_contact['phone']"
              required
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="block-content block-content-full block-content-sm bg-body-light text-end"
    >
      <button type="submit" class="btn btn-alt-success">Save</button>
    </div>
  </form>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import debounce from "lodash/debounce";
import AddressAutocomplete from "@/components/addressAutocomplete.vue";
import { useFetch } from "@/components/useFetch.js";
import Select from "@/components/form/select.vue";

const hasUnsavedChanges = ref(false);
const isSaving = ref(false); // New ref to track save action

const props = defineProps({
  route: String,
  paymentTermsOptions: {
    type: Object,
  },
  customer: {
    type: Object,
    default: () => ({
      id: null,
      name: null,
      office_address: {
        line_1: null,
        line_2: null,
        city: null,
        county: null,
        postcode: null,
      },
      office_email: null,
      office_phone: null,
      company_number: null,
      primary_contact: {
        name: null,
        email: null,
        phone: null,
      },
    }),
  },
});

const companieshousesearch = ref("");
const companyResults = ref([]);
const selectedCompany = ref(null);
const hasSearched = ref(false);
const formFields = ref({
  id: props.customer.id ?? null,
  name: props.customer.name ?? null,
  default_payment_term_type: props.customer.default_payment_term_type ?? null,
  default_payment_term_days: props.customer.default_payment_term_days ?? null,
  office_address: {
    line_1: props.customer.office_address?.line_1 ?? null,
    line_2: props.customer.office_address?.line_2 ?? null,
    city: props.customer.office_address?.city ?? null,
    county: props.customer.office_address?.county ?? null,
    postcode: props.customer.office_address?.postcode ?? null,
  },
  office_email: props.customer.office_email ?? null,
  office_phone: props.customer.office_phone ?? null,
  company_number: props.customer.company_number ?? null,
  primary_contact: {
    name: props.customer.primary_contact?.name ?? null,
    email: props.customer.primary_contact?.email ?? null,
    phone: props.customer.primary_contact?.phone ?? null,
  },
});

async function fetchCompanies() {
  if (companieshousesearch.value.length < 3) {
    return;
  }

  const response = await useFetch(
    route("api.services.companies-house.search"),
    {
      method: "POST",
      body: { query: companieshousesearch.value },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  const data = await response.json();
  hasSearched.value = true;

  companyResults.value = data.items;
}

function selectCompany(key) {
  selectedCompany.value = companyResults.value[key];
  formFields.value.company_number = selectedCompany.value.company_number;
  formFields.value.name = selectedCompany.value.company_name;
  formFields.value.office_address.line_1 =
    selectedCompany.value.registered_office_address.address_line_1;
  formFields.value.office_address.line_2 =
    selectedCompany.value.registered_office_address.address_line_2;
  formFields.value.office_address.city =
    selectedCompany.value.registered_office_address.locality;
  formFields.value.office_address.postcode =
    selectedCompany.value.registered_office_address.postal_code;
}

async function createCustomer() {
  function getCustomerResponse() {
    isSaving.value = true;
    if (props.customer.id == null) {
      return useFetch(route("api.customers.store"), {
        method: "POST",
        body: formFields.value,
      });
    } else {
      return useFetch(
        route("api.customers.update", { customer: props.customer.id }),
        {
          method: "PUT",
          body: formFields.value,
        }
      );
    }
  }

  const customerResponse = await getCustomerResponse();
  const customerData = await customerResponse.json();

  if (customerResponse.status === 201 || customerResponse.status === 200) {
    isSaving.value = false;
    hasUnsavedChanges.value = false;
    window.location.href = route("customers.show", {
      customer: customerData.id,
    });
  } else {
    isSaving.value = false;
    const errorMessages = Object.values(customerData.errors)
      .flat()
      .join("<br/><br/>");
    await Swal.fire({
      icon: "error",
      title: "Error",
      html:
        "<h4>There was an error creating the customer.</h4>" + errorMessages,
    });
  }
}

function handleAddressSelected(address) {
  formFields.value.office_address.line_1 = address.line_1;
  formFields.value.office_address.line_2 = address.line_2;
  formFields.value.office_address.city = address.town_or_city;
  formFields.value.office_address.county = address.county;
  formFields.value.office_address.postcode = address.postcode;
}

watch(
  formFields,
  () => {
    hasUnsavedChanges.value = true;
  },
  { deep: true }
);

// Add beforeunload event listener
onMounted(() => {
  window.addEventListener("beforeunload", handleBeforeUnload);
});

function handleBeforeUnload(event) {
  if (hasUnsavedChanges.value && !isSaving.value) {
    event.preventDefault();
    event.returnValue = "";
  }
}

watch(companieshousesearch, debounce(fetchCompanies, 500));
</script>
