<template>
  <Modal
    title="Select Customer"
    modal-classes="modal-xl"
    @close="() => emit('close')"
    @save="selectCustomer"
  >
    <form @submit.prevent="selectCustomer">
      <div class="mb-3">
        <h5>Search for existing customer</h5>
        <input
          v-model="customerSearchTerm"
          name="customerSearchTerm"
          class="w-100 form-control"
          placeholder="Enter a customer name"
        />
      </div>

      <div v-if="existingCustomers.length > 0" class="mb-3">
        <label class="form-label">Select Existing Customer</label>
        <select v-model="selectedCustomer" class="form-select">
          <option
            v-for="customerExisting in existingCustomers"
            :key="customerExisting.id"
            :value="customerExisting"
          >
            {{ customerExisting.name }}
          </option>
        </select>
      </div>
    </form>
  </Modal>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFetch } from "@/components/useFetch.js";
import Modal from "@/components/modal.vue";

const props = defineProps({
  customer: {
    type: Object,
    default: () => ({
      id: null,
      name: null,
    }),
    required: false,
  },
});
const debounceTimeout = ref(0);

const existingCustomers = ref([]);

const selectedCustomer = ref(props.customer);

const errors = ref({});

async function selectCustomer() {
  errors.value = {};
  if (selectedCustomer.value.id === null) {
    const response = await useFetch(route("api.customers.store"), {
      method: "POST",
      body: selectedCustomer.value,
    });
    const result = await response.json();

    if (response.ok) {
      emit("customer-selected", result);
    } else {
      if (result.errors) {
        for (const key in result.errors) {
          if (Array.isArray(result.errors[key])) {
            errors.value[key] = result.errors[key].join(" ");
          } else {
            errors.value[key] = result.errors[key];
          }
        }
      } else {
        alert("Failed to save customer");
      }
    }
  } else {
    emit("customer-selected", selectedCustomer.value);
  }
}

async function searchCustomers() {
  const response = await useFetch(
    route("api.customers.search", { term: customerSearchTerm.value })
  );
  existingCustomers.value = await response.json();
}

const customerSearchTerm = ref("");

watch(customerSearchTerm, () => {
  if (debounceTimeout.value) {
    clearTimeout(debounceTimeout.value);
  }
  debounceTimeout.value = setTimeout(() => {
    searchCustomers();
  }, 300);
});

const emit = defineEmits(["close", "customer-selected"]);
</script>
