<template>
  <div v-if="editor" class="container">
    <div class="control-group">
      <div class="button-group">
        <button
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
          type="button"
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        >
          H1
        </button>
        <button
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          type="button"
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        >
          H2
        </button>
        <button
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          type="button"
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        >
          H3
        </button>
        <div class="button-break"></div>
        <button
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
          type="button"
          @click="editor.chain().focus().toggleBold().run()"
        >
          Bold
        </button>
        <button
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
          type="button"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          Italic
        </button>
        <button
          :disabled="!editor.can().chain().focus().toggleStrike().run()"
          :class="{ 'is-active': editor.isActive('strike') }"
          type="button"
          @click="editor.chain().focus().toggleStrike().run()"
        >
          Strike
        </button>
        <button
          :class="{ 'is-active': editor.isActive('paragraph') }"
          type="button"
          @click="editor.chain().focus().setParagraph().run()"
        >
          Paragraph
        </button>
        <button
          :class="{ 'is-active': editor.isActive('bulletList') }"
          type="button"
          @click="editor.chain().focus().toggleBulletList().run()"
        >
          Bullet list
        </button>
      </div>
    </div>
    <editor-content :editor="editor" class="tiptapEditor" />
  </div>
</template>

<script>
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-3";

export default {
  components: {
    EditorContent,
  },

  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    modelValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [StarterKit],
      content: this.modelValue,
      onUpdate: () => {
        // HTML
        this.$emit("update:modelValue", this.editor.getHTML());

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 1rem;
}

/* Button styles */

.button-break {
  border: 1px solid #4b4b4b;
}

.button-group {
  display: flex;
  gap: 0.5rem;
}

.button-group > button {
  background: none;
  border-radius: 0.25rem;
  color: #000000;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.2rem 0.5rem;
  transition: background-color 0.2s;
  font-weight: bold;
  box-shadow: none;
  border: 1px solid #4b4b4b;

  &:hover {
    background-color: #b6b6b6;
  }

  &.is-active {
    background-color: #1c45ac;
    color: #ffffff;
  }
}

/* Editor styles */
.tiptapEditor {
  border: 1px solid #4b4b4b;
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 1rem;
  background: #eee;
}

.tiptapEditor {
  background: #eee;
}

/* Basic editor styles */
.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    text-wrap: pretty;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */
  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: "JetBrainsMono", monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }
}
</style>
