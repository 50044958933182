<template>
  <div ref="mapElement" style="width: 100%; height: 800px"></div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

// Define props
const props = defineProps({
  apiKey: String,
  mapId: String,
  locations: Array,
});

// Create a ref for the map element
const mapElement = ref(null);

// Function to load the Google Maps script
const loadGoogleMaps = (apiKey) => {
  return new Promise((resolve, reject) => {
    if (typeof google !== "undefined") {
      resolve(google);
      return;
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=marker`;
    script.async = true;
    script.defer = true;
    script.onload = () => resolve(window.google);
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

// Initialize the Google Map
const initializeMap = async () => {
  const google = await loadGoogleMaps(props.apiKey);
  const map = new google.maps.Map(mapElement.value, {
    center: {
      lat: 53.230553360548186,
      lng: -0.5408974548965823,
    },
    zoom: 10,
    mapId: props.mapId,
  });

  // Add markers using the AdvancedMarkerElement API
  props.locations.forEach((location) => {
    const markerOptions = {
      position: new google.maps.LatLng(location.latitude, location.longitude),
      map: map,
      title: `Marker at ${location.latitude}, ${location.longitude}`,
    };

    const icon = document.createElement("div");
    icon.style.width = "24px";
    icon.style.height = "24px";
    icon.style.backgroundColor = location.color;
    icon.style.borderRadius = "50%";
    icon.title = markerOptions.title;

    const advancedMarker = new google.maps.marker.AdvancedMarkerElement({
      map: map,
      position: markerOptions.position,
      content: icon, // Custom content (colored dot)
    });

    let textHeader = location.title;
    if (location.type == "Quote" || location.type == "Job") {
      textHeader = location.type + " #" + location.id;
    }

    // Create an info window for each marker
    const infoWindow = new google.maps.InfoWindow({
      content: `
<div>
    <strong>${textHeader}</strong><br>
    Customer: ${location.title}<br><br>
    <a href="${location.link}" target="_blank">View ${location.type}</a>
</div>`,
    });

    // Add event listener to show info window when the marker is clicked
    advancedMarker.addListener("click", () => {
      infoWindow.open({
        anchor: advancedMarker,
        map,
        shouldFocus: false,
      });
    });

    advancedMarker.setMap(map);
  });
};

// Watch the locations prop to update the map when locations change
watch(
  () => props.locations,
  () => {
    initializeMap();
  }
);

// Mount the map when the component is loaded
onMounted(() => {
  initializeMap();
});
</script>
