<template>
  <div class="d-none d-lg-block">
    <div v-if="mode == 'initial'" class="text-center mt-4">
      <p v-if="wantsDarkMode">
        Dark Mode Enabled.
        <span @click="disable">Disable?</span>
      </p>
      <p v-else>
        Dark Mode Disabled.
        <span @click="enable">Enable?</span>
      </p>
    </div>
    <div v-else>
      <button
        v-if="wantsDarkMode"
        :class="['btn', 'text-white']"
        @click="disable"
      >
        <i class="far fa-lightbulb"></i>
      </button>
      <button v-else class="btn text-warning" @click="enable">
        <i class="fas fa-lightbulb"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  props: {
    mode: {
      type: String,
      default: "toggle",
    },
  },

  setup(props) {
    const wantsDarkMode = ref(false);

    const enable = () => {
      deleteCookie("wants_light_mode");
      setCookie("wants_dark_mode");
      wantsDarkMode.value = true;
      toggleClass();
    };

    const disable = () => {
      deleteCookie("wants_dark_mode");
      setCookie("wants_light_mode");
      wantsDarkMode.value = false;
      toggleClass();
    };

    const deleteCookie = (name) => {
      document.cookie =
        name +
        "=checked;path=/;expires=" +
        new Date(Date.now() - 1000).toUTCString();
    };

    const setCookie = (name) => {
      document.cookie =
        name +
        "=checked;path=/;expires=" +
        new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString();
    };

    const toggleClass = () => {
      const pageContainer = document.getElementById("page-container");
      if (pageContainer) {
        pageContainer.classList.toggle("dark-mode");
        pageContainer.classList.toggle("page-header-dark");
      }
    };

    onMounted(() => {
      const cookies = document.cookie.split(";").map((cookie) => {
        const [name] = cookie.split("=");
        return name.trim();
      });

      const wantsDarkModeCookie = cookies.includes("wants_dark_mode");
      const wantsLightModeCookie = cookies.includes("wants_light_mode");

      if (wantsLightModeCookie) {
        wantsDarkMode.value = false;
      } else if (wantsDarkModeCookie) {
        wantsDarkMode.value = true;
      } else if (
        matchMedia &&
        matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        enable();
      }
    });

    return {
      wantsDarkMode,
      enable,
      disable,
    };
  },
};
</script>

<style scoped>
p {
  user-select: none;
}
span {
  cursor: pointer;
}
</style>
